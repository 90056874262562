import { useContext } from "react"
import MyContext from "../../../../components/MyContext"

export const CategoriesStyle = () => {
    const myContext = useContext(MyContext)

    const getAccentColor = () => {
        return myContext.theme_settings?.accent_color?.value || '#F8931D'
    }

    if (myContext.theme_settings) return (
        <style jsx="true">{`
            #tabs-32sdf4ddsff32 .nav-tabs .nav-link {
                border: none;
                margin: 0;
            }
            #tabs-32sdf4ddsff32 .nav-tabs .nav-link.active {
                border-bottom: 2px solid ${getAccentColor()};
                color:${getAccentColor()};
                font-weight:700;     
            }            
            #tabs-32sdf4ddsff32 .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                color: ${getAccentColor()};
                font-weight:700;  
            }
            #tabs-32sdf4ddsff32 .nav-tabs a:hover {
                color: ${getAccentColor()};
            }
        `}</style>
    )
    else return null
}
