import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import CategoryContent from "./CategoryContent"
import CustomImage, { PublicStorageFolderPath } from '../../../../components/helpers/CustomImage';
import axios from 'axios';
import SwalToast from '../../../../components/helpers/SwalToast';
import Config from '../../../../components/axios/Config';
import Cookies from 'js-cookie';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import LoadingProgress from "../../../../components/helpers/LoadingProgress";
import SelectCategory from '../components/SelectCategory';
import Responsive from '../../../../components/helpers/Responsive';
import { CategoriesStyle } from '../components/Style';
import MobileTab from '../components/MobileTab';

const AllCategories = () => {
    const { t } = useTranslation();

    const [key, setKey] = useState('all');
    const [tabName, setTabName] = useState([]);
    const [dataBanner, setDataBanner] = useState(undefined)
    const [listCategories, setListCategories] = useState([])

    useEffect(() => {
        getBanner()
    }, [])

    useEffect(() => {
        getCategories()
    }, [])

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 767, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };


    const getBanner = () => {

        let url = `${process.env.REACT_APP_BASE_API_URL}forum/banner/get`;

        axios.get(url, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        })

        ).then(response => {
            let data = response.data.data;
            setDataBanner(data);
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    const getCategories = () => {
        let url = `${process.env.REACT_APP_BASE_API_URL}forum/categories/get`;
        let category = []
        axios.get(url, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        })).then(response => {
            let data = response.data.data
            // if (params.id === "all") {
            category = data.map((item) => (
                item.parent_id !== null &&
                {
                    name: item.name,
                    id: item.id,
                    slug: item.slug,
                }
            ))
            setTabName(category.filter(x => !!x))
            // } else {

            //     let x = data.filter((item) => item.parent_id === parseInt(params.id))
            //     setTabName(x)
            // }
            setListCategories(data)
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    return (<>
        {dataBanner === undefined && <LoadingProgress />}
        {dataBanner === null && <></>}
        {dataBanner && <>
            <CategoriesStyle />
            <div style={{ marginTop: -16 }}>

                {/* {/* <h4 className='h4 font-weight-bold'>Tokodapur Forum</h4> */}
                {/* <h6 className='h6 font-weight-semi-bold'>Tanya jawab seputar ilmu dapur di sini</h6> */}
            </div>
            <Carousel
                responsive={responsive}
                autoPlay={dataBanner?.auto_play == "disable" ? false : true}
                autoPlaySpeed={dataBanner?.slideshow_speed}
                transitionDuration={dataBanner?.animation_speed}
                infinite={dataBanner?.auto_play == "disable" ? false : true}
                showDots={dataBanner?.indicator === "pagination"}
            >
                {dataBanner?.forum_banner_slide.map((image, index) => (
                    <div key={index}>
                        <CustomImage
                            folder={PublicStorageFolderPath.cms}
                            style={{
                                width: parseInt(dataBanner.width),
                                height: parseInt(dataBanner?.height),
                                borderRadius: 10
                            }}
                            filename={image?.filename}
                            alt={image?.filename} />
                    </div>
                ))}
            </Carousel>
        </>}

        <Responsive desktop={
            <div id="tabs-32sdf4ddsff32">
                <Tabs id="controlled-tab" className="mb-3 mt-3" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab tabClassName="" eventKey="all" title={t('forum_all')}>
                        <CategoryContent />
                    </Tab>
                    {tabName.map((tab) => (
                        <Tab
                            key={tab.id}
                            tabClassName=""
                            eventKey={tab.slug}
                            title={tab.name}>
                            <CategoryContent
                                categorySlug={tab.slug}
                            />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        } mobile={<>
            <div className="my-2">
                <SelectCategory
                    listCategories={listCategories}
                />
            </div>

            <div className='d-flex overflow-auto'>
                <MobileTab
                    onClick={setKey}
                    slug={"all"}
                    activeKey={key}
                    label={t('forum_all')}
                />
                {tabName.map((tab) => (
                    <MobileTab key={tab.id}
                        onClick={setKey}
                        slug={tab.slug}
                        activeKey={key}
                        label={tab.name}
                    />
                ))}
            </div>

            <div>
                <div hidden={key !== "all"}>
                    <CategoryContent />
                </div>
                {tabName.map((tab) => (<div key={tab.id} hidden={tab.slug !== key}>
                    <CategoryContent
                        categorySlug={tab.slug}
                    />
                </div>))}
            </div>
        </>} />
    </>)
}

export default AllCategories
