import { useState } from "react"
import { useTranslation } from "react-i18next"
import { CurrencyFormat2 } from "../../../components/helpers/CurrencyFormat"
import PriceRoundUp from "../../../components/payment/PriceRoundUp"
import { getProductPrice } from "./HelperCartCheckout"

/**
 * 
 * @param {object} cart object of cart. Contains cart id, mp_product, mp_product_sku
 * @param {decimal} insurancePercentage company setting of insurance percentage
 * @param {function} onChange runs when input is changed
 * @returns 
 */
const CheckoutUseInsurance = (props) => {
    const [use_courier_insurance, set_use_courier_insurance] = useState(props.cart.mp_product.courier_insurance === "required")

    const { t } = useTranslation()

    const onChange = (e) => {
        set_use_courier_insurance(e.target.checked)
        props.onChange(props.cart.id, e.target.checked)
    }

    const calculateInsurance = () => {
        return PriceRoundUp((getProductPrice(props.cart) * props.cart.quantity * props.insurancePercentage) / 100, 100)
    }

    return (
        <div className="form-check">
            <input className="form-check-input" type="checkbox"
                checked={use_courier_insurance}
                onChange={onChange}
                id={"use_courier_insurance-" + props.cart.id}
                disabled={props.cart.mp_product.courier_insurance === "required"} />
            <label className="form-check-label" htmlFor={"use_courier_insurance-" + props.cart.id}>
                {t('checkout.use_insurance_for_delivery')} (Rp {CurrencyFormat2(calculateInsurance())})
            </label>
        </div>
    )
}

export default CheckoutUseInsurance