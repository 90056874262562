
import React, { PureComponent } from 'react';
import { TinyMceContent } from "../../../components/helpers/TinyMceEditor";

/**
 * 
 * @param {string} content
 * @returns 
 */
class SellerInformation extends PureComponent {
    render() {
        return (
            <div className="bg-white shadow-graph rounded p-3 mt-4">
                <TinyMceContent className=" mt-3">
                    {this.props.content}
                </TinyMceContent>
            </div>
        )
    }
}

export default SellerInformation