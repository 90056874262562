const paymentMethodOptions = [
    {
        key: "credit_card",
        filename: "CREDITCARD.png",
    }, {
        group: "va",
        items: [{
            key: "bca_va",
            filename: "BCA.png",
        }, {
            key: "echannel",
            filename: "MANDIRI.png",
        }, {
            key: "bni_va",
            filename: "BNI.png",
        }, {
            key: "permata_va",
            filename: "PERMATA.png",
        }, {
            key: "bri_va",
            filename: "BRI.png",
        }, {
            key: "other_va",
            filename: "ATM BERSAMA.png",
        }]
    }, {
        key: "gopay",
        filename: "GOPAY QRIS.png",
    }, {
        group: "instant_debit",
        items: [{
            key: "bca_klikpay",
            filename: "BCA KLIKPAY.png",
        }, {
            key: "cimb_clicks",
            filename: "OCTO.png",
        }, {
            key: "danamon_online",
            filename: "DANAMON.png",
        }]
    }, {
        group: "cstore",
        items: [{
            key: "indomaret",
            filename: "INDOMARET.png",
        }, {
            key: "alfamart",
            filename: "ALFAGROUP.png",
        }]
    }, {
        group: "cardless_credit",
        items: [{
            key: "akulaku",
            filename: "AKULAKU.png",
        }]
    }, {
        key: "manual",
        filename: "MANUAL TRANSFER.png",
    },
]

export default paymentMethodOptions