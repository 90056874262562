import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Link, matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Config from '../../../../components/axios/Config';
import CustomImage, { PublicStorageFolderPath } from '../../../../components/helpers/CustomImage';
import SwalToast from '../../../../components/helpers/SwalToast';
import GeneralRoutePath from '../../GeneralRoutePath';
import { TinyMcePreview } from '../../../../components/helpers/TinyMceEditor';
import moment from 'moment';
import ReactSelect from 'react-select';
import ErrorDiv from '../../../../components/helpers/ErrorDiv';
import { Modal } from "react-bootstrap";
import { Options } from '../components/ReportCategory';
import { isLogin } from '../components/IsLogin';
import Paginate from '../../../../components/helpers/Paginate';
import { useTranslation } from 'react-i18next';
import EcommerceRoutePath from '../../../e-commerce/EcommerceRoutePath';
import TextTruncate from '../../../../components/helpers/TextTruncate';
import { useRef } from 'react';
import MyContext from '../../../../components/MyContext';

// const getCategoryContentType = (match) => {
//     if (matchPath(match.path, GeneralRoutePath.FORUM_MY_CATEGORY)) return "all"
//     else if (matchPath(match.path, GeneralRoutePath.FORUM_MY_SELECT_CATEGORY)) return "specific"
//     else return "all"
// }

const CategoryContent = ({ categorySlug }) => {
    // const match = useRouteMatch()
    let history = useHistory();
    const myContext = useContext(MyContext)
    const { t } = useTranslation()

    // const type = getCategoryContentType(match)
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {}

    const [data, setData] = useState([])
    const [ids, setIds] = useState([])
    const [selected, setSelected] = useState([])
    const [modalReport, setModalReport] = useState()
    const [description, setDescription] = useState("")
    const [error, setError] = useState({})
    const [lastPage, setLastPage] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('');

    const init = useRef(false)

    useEffect(() => {
        getThread()
    }, [page, categorySlug])

    useEffect(() => {
        if (!init.current) return

        let timeout = setTimeout(() => {
            getThread()
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [search])

    const getThread = () => {

        let url = `${process.env.REACT_APP_BASE_API_URL}forum/thread/get`;
        let params = {
            page: page,
            per_page: 10,
            search: search,
            category_slug: categorySlug
        }

        let config = Config(null, params)
        if (isLogin()) {
            config = Config({ Authorization: `Bearer ${Cookies?.get("token")}` }, params)
        }

        axios.get(url, config, params).then(response => {
            let data = response.data.data;
            setData(data.data);
            setLastPage(data.last_page)
            init.current = true

        }).catch(error => {
            console.log(error)
            if (error.response) {
                console.log(error.response)
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    const goToDetails = (slug) => {
        history.push(GeneralRoutePath.FORUM_DETAIL_THREAD.replace(':slug', slug))
    }

    const onLikeThread = (id) => {
        if (!isLogin()) {
            SwalToast.fire({
                icon: "error",
                title: "Silahkan login terlebih dahulu"
            })
            return
        } else {
            let url = `${process.env.REACT_APP_BASE_API_URL}forum/thread/sendLike`

            let body = {
                forum_thread_id: parseInt(id)
            }

            axios.post(url, body, Config({
                Authorization: `Bearer ${Cookies.get("token")}`,
            })).then(response => {
                console.log(response.data.message);
                getThread()
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const onUnlikeThread = (id) => {
        if (!isLogin()) {
            SwalToast.fire({
                icon: "error",
                title: "Silahkan login terlebih dahulu"
            })
            return
        } else {
            let url = `${process.env.REACT_APP_BASE_API_URL}forum/thread/unLike`

            let body = {
                forum_thread_id: parseInt(id)
            }
            axios.post(url, body, Config({
                Authorization: `Bearer ${Cookies.get("token")}`,
            })).then(response => {
                console.log(response.data.message);
                getThread()
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const handleModal = (id) => {
        if (!isLogin()) {
            SwalToast.fire({
                icon: "error",
                title: "Silahkan login terlebih dahulu"
            })
        } else {
            setModalReport(true)
            setIds(id)
        }
    }

    const onReportThread = () => {

        if (!validation()) return

        let url = `${process.env.REACT_APP_BASE_API_URL}forum/thread/report/save`

        let category = []
        selected.forEach(i => category.push(i.value))

        let body = {
            forum_thread_id: parseInt(ids),
            description: description,
            category: category,
        }

        axios.post(url, body, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        })).then(response => {
            console.log(response.data.message);
            SwalToast.fire({
                icon: "success",
                title: response.data.message
            })
            getThread()
            setModalReport(false)
            setSelected()
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }
    const onSelectedCategory = (e) => {
        setSelected(e)
    }

    const onChangeStatus = (id_thread) => {
        if (!isLogin()) {
            SwalToast.fire({
                icon: "error",
                title: "Silahkan login terlebih dahulu"
            })
        } else {

            let url = `${process.env.REACT_APP_BASE_API_URL}forum/thread/bookmark/save`;

            let data = {
                forum_thread_id: id_thread,
            }
            // console.log(data);
            // return
            axios.post(url, data, Config({
                Authorization: `Bearer ${Cookies.get('token')}`
            })).then(response => {
                SwalToast.fire({ icon: "success", title: response.data.message })
                getThread()
            }).catch(error => {
                console.log(error);
                if (error.response) {
                    console.log(error.response.data);
                    SwalToast.fire({ icon: "error", title: error.response.data.message })
                }
            })
        }
    }

    const validation = () => {
        let validate = true
        let errors = []
        if (selected.length == 0) {
            errors.select = "Pilih Kategori terlebih dahulu"
            validate = false
        }
        else if (description == "") {
            errors.desc = "Keterangan harus diisi"
            validate = false
        }
        setError(errors)
        return validate
    }

    const handlePage = (selected) => {
        setPage(selected)
    }

    const onCreateAddForum = () => {
        if (!isLogin()) {
            SwalToast.fire({
                icon: "error",
                title: t('forum_please_login')
            })
        } else {
            history.push(GeneralRoutePath.FORUM_CREATE)
        }
    }

    return (
        <>
            <style>{`
                @media (min-width: 768px) {
                    .custom-d-flex {
                        display: flex;
                    }      
                    .search {
                        width:70%;
                    }                  
                }
                @media (min-width: 767.98px) {
                    .hide {
                        display: none;
                    }        
                    .search {
                        max-width:100%;
                    }                     
                }
            `}</style>

            <div className='custom-d-flex justify-content-between align-items-center px-2'>
                <div className='d-flex my-3 justify-content-between align-items-center'>
                    <div className=''>
                        <h4 className='h4 font-weight-bold'>Thread</h4>
                    </div>
                    <button className="text-white bgc-accent-color border px-3 py-2 hide" style={{ cursor: 'pointer', borderRadius: 7 }} onClick={onCreateAddForum}>
                        <i className="fas fa-pen"></i>  {t('forum_create_new_thread')}
                    </button>
                </div>
                <div className='mb-3 search'>
                    <div className="input-group w-100">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cari Thread"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button className='btn bgc-accent-color'>
                                <i className="fa fa-search" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {data.length > 0 ? data.map((item) => (
                <div className="bg-white shadow-graph rounded p-3 mb-3" style={{ top: 0 }} key={item.id}>
                    <Link to={
                        EcommerceRoutePath.CUSTOMER.replace(":id", item.user_id)
                    }
                        className="text-decoration-none"
                    >
                        <div className="d-flex align-items-center">
                            <div>

                                <CustomImage
                                    folder={item?.user_type === "seller" ? PublicStorageFolderPath.seller : PublicStorageFolderPath.customer}
                                    filename={item?.picture}
                                    style={{ width: 50, height: 50, borderRadius: 50 }}
                                    className="mr-2"
                                />
                            </div>
                            <div>
                                <div className='d-flex align-items-center'>
                                    <h6 className='h6 font-weight-bold color-black'>{item.name}</h6>
                                    {/* <i className="fas fa-check-circle accent-color mx-1 align-self-start mt-1"></i> */}
                                    <div className='align-self-start ml-2'>
                                        {item?.user_type === "seller" &&
                                            <div>
                                                <p
                                                    className='bgc-accent-color'
                                                    style={{
                                                        width: "auto",
                                                        color: "#fff",
                                                        fontSize: 10,
                                                        borderRadius: 5,
                                                        padding: 3
                                                    }}
                                                >
                                                    <i className="fas fa-store mr-1"></i>
                                                    Seller
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <p className='small color-black'>
                                    {/* {moment(new Date(item?.created_at)).fromNow()} */}
                                    {moment(new Date(item?.created_at)).fromNow()}
                                </p>
                            </div>
                        </div>
                    </Link>
                    <div onClick={() => goToDetails(item.slug)} className="cursor-pointer color-black" >
                        <div className='d-flex flex-column mt-4'>
                            <h4 className='h4 font-weight-bold '>
                                {item?.title}
                            </h4>
                            <TextTruncate className="color-black" lineClamp={5}>
                                <TinyMcePreview>{item.content}</TinyMcePreview>
                            </TextTruncate>
                        </div>
                        <div className='d-flex mt-2 flex-wrap'>
                            {item?.categories.map((category) => (
                                <div key={category.id}>
                                    <p
                                        className='small color-black'
                                        style={{
                                            backgroundColor: "#eee",
                                            width: "auto",
                                            height: "auto",
                                            borderRadius: 50,
                                            padding: 8,
                                            textAlign: "center",
                                            marginRight: 5,
                                            marginTop: 10
                                        }}
                                    >
                                        {category?.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='d-flex mt-2 justify-content-between mt-3'>
                        <div className='d-flex align-items-center'>
                            {item.is_like === true ?
                                (
                                    <div onClick={() => onUnlikeThread(item.id)} className='cursor-pointer accent-color'>
                                        <i className="fas fa-thumbs-up mr-2"></i>
                                    </div>
                                )
                                :
                                (
                                    <div onClick={() => onLikeThread(item.id)} className='cursor-pointer color-6D6D6D'>
                                        <i className="fas fa-thumbs-up mr-2 "></i>
                                    </div>
                                )
                            }
                            <p className='small'>{item.total_like}</p>
                        </div>
                        <div className='d-flex'>
                            <i className="fas fa-comments mr-2 color-6D6D6D"></i>
                            <p className='small'>{item.total_comment}</p>
                        </div>
                        <div className='d-flex'>
                            <i className="fas fa-eye mr-2 color-6D6D6D"></i>
                            <p className='small'>{item.counter}</p>
                        </div>
                        {/* <div className='cursor-pointer' onClick={() => onDeleteThread(item?.id)}>
                            <i className="fas fa-trash color-6D6D6D" ></i>
                        </div> */}
                        <div className="dropdown mr-1 cursor-pointer btn-group dropleft">
                            <i className="fas fa-ellipsis-v color-6D6D6D" data-toggle="dropdown" aria-expanded="false" data-offset="10,20"></i>
                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={() => handleModal(item.id)}>{t('forum_report')}</div>
                                <div className="dropdown-item" onClick={() => onChangeStatus(item.id)}>{t('forum_save')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
                :
                <center>
                    <div className="d-flex justify-content-center">
                        <img
                            src={`/images/empty_folder.png`}
                            className="empty-state"
                            onError={event => event.target.src = `/images/placeholder.gif`}
                            style={{ width: 250, height: "auto" }}
                        />
                    </div>
                    <h5 className='h5'>{t('forum_not_yet')}</h5>
                </center>
            }
            {
                (data && lastPage > 0) &&
                <div className="d-flex justify-content-end mt-3">
                    <Paginate
                        pageCount={lastPage}
                        onPageChange={selected => handlePage(selected)}
                        initialPage={page}
                    />
                </div>
            }
            <Modal
                size="lg"
                centered
                show={modalReport}
                onHide={() => setModalReport(false)}>
                <Modal.Header closeButton>
                    <h6 className='font-weight-bold'>
                        {t('forum_report')} Thread
                    </h6>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h6 className='small' style={{ textAlign: "justify" }}>{t('forum_sorry_for_the_inconvenience', { username: user.name, companyname: myContext.companyName })}</h6>

                        <h6 className='small'>{t('forum_category')} Laporan</h6>
                        <div className='w-50'>
                            <ReactSelect
                                options={Options}
                                isMulti
                                onChange={(c) => onSelectedCategory(c)}
                            />
                            <ErrorDiv error={error.select} />
                        </div>
                        <div className='mt-3 w-100'>
                            <textarea
                                style={{
                                    color: '#000000',
                                    backgroundColor: '#FFF',
                                    borderWidth: 0.5,
                                    borderColor: "#AAAAAA",
                                    borderRadius: 10,
                                    height: 140,
                                    padding: 10
                                }}
                                className='w-100'
                                name='report_desc'
                                onChange={(e) => setDescription(e.target.value)}
                            >
                            </textarea>
                            <ErrorDiv error={error.desc} />
                        </div>
                        <div className='d-flex justify-content-end align-items-center mt-3'>
                            <div className=''>
                                <button onClick={() => setModalReport(false)} className="btn btn-sm border-CED4DA button-nowrap  mx-3 rounded p-2 font-weight-bold" style={{ width: 150 }}>
                                    {t('forum_back')}
                                </button>
                                <button onClick={() => onReportThread()} className="btn btn-sm text-white bgc-EC9700 button-nowrap rounded p-2 font-weight-bold" style={{ width: 150 }}>
                                    {t('forum_send')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CategoryContent;