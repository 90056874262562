import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Config from '../../../../components/axios/Config';
import SwalToast from '../../../../components/helpers/SwalToast';
import CategoryContent from './CategoryContent';
import { Tabs, Tab } from "react-bootstrap";
import Responsive from '../../../../components/helpers/Responsive';
import SelectCategory from '../components/SelectCategory';
import { CategoriesStyle } from '../components/Style';
import MobileTab from '../components/MobileTab';

const SpecificCategories = () => {
    const params = useParams();

    const [key, setKey] = useState(params.slug);
    const [listCategories, setListCategories] = useState([])

    useEffect(() => {
        setKey(params.slug)
        setListCategories([])
        getCategories()
    }, [params.slug])

    const getCategories = () => {
        let url = `${process.env.REACT_APP_BASE_API_URL}forum/categories/get`;

        let paramss = {
            parent_slug: params.slug
        }

        axios.get(url, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        }, paramss)).then(response => {
            let data = response.data.data;
            setListCategories(data)
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    return (
        <>
            <CategoriesStyle />

            <Responsive desktop={
                <div id="tabs-32sdf4ddsff32">
                    <Tabs id="controlled-tab" className="mb-3 mt-3" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Tab tabClassName="" eventKey={params.slug} title={params.slug}>
                            <CategoryContent
                                categorySlug={params.slug}
                            />
                        </Tab>
                        {listCategories.map((tab, index) => (
                            <Tab
                                key={tab.id}
                                tabClassName=""
                                eventKey={index}
                                title={tab.slug}>
                                <CategoryContent
                                    categorySlug={tab.slug}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            } mobile={<>
                <div className="my-2">
                    <SelectCategory />
                </div>

                <div className='d-flex overflow-auto'>
                    <MobileTab
                        onClick={setKey}
                        slug={params.slug}
                        activeKey={key}
                        label={params.slug}
                    />
                    {listCategories.map((tab) => (
                        <MobileTab key={tab.id}
                            onClick={setKey}
                            slug={tab.slug}
                            activeKey={key}
                            label={tab.name}
                        />
                    ))}
                </div>

                <div>
                    <div hidden={key !== params.slug}>
                        <CategoryContent
                            categorySlug={params.slug}
                        />
                    </div>
                    {listCategories.map((tab) => (<div key={tab.id} hidden={tab.slug !== key}>
                        <CategoryContent
                            categorySlug={tab.slug}
                        />
                    </div>))}
                </div>
            </>} />
        </>
    );
}

export default SpecificCategories;