/**
 * 
 * @param {decimal} previous_price the price that wanted to be rounded up
 * @param {int} round_amount amount to round. For example if you want to nearest 100 from 5.129 to 5.200, then put 100
 * @returns 
 */
const PriceRoundUp = (previous_price, round_amount = 1) => {
    let divide_by_round_amount = previous_price / round_amount;
    let round_up_digit = Math.ceil(divide_by_round_amount);
    let result = round_up_digit * round_amount;

    return result
}

export default PriceRoundUp