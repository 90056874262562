import React, { useState, useEffect } from 'react';
import CustomImage, { PublicStorageFolderPath } from '../../../../components/helpers/CustomImage';
import MyContext from '../../../../components/MyContext';
import Template from '../../../../components/Template';
import { Tabs, Tab, Modal } from "react-bootstrap";
import TinyMceEditor, { TinyMceContent } from '../../../../components/helpers/TinyMceEditor';
import EventCard from '../components/EventCard';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import SwalToast from '../../../../components/helpers/SwalToast';
import Cookies from 'js-cookie';
import Config from '../../../../components/axios/Config';
import Paginate from '../../../../components/helpers/Paginate';
import { optionExpertiseLevel } from '../components/WebinarOptions';
import { isLogin } from '../../forum/components/IsLogin';
import { useTranslation } from 'react-i18next';
import SpeakerListReview from '../components/SpeakerListReview';
import SpeakerDiscussion from '../components/SpeakerDiscussion';
import Responsive from '../../../../components/helpers/Responsive';

const Styles = props => {
    if (props.themes) {
        return (
            <style>{`
              #body {
                  max-width: ${props.themes.site_width.width};
                  margin: 0 auto;
              }
              @media (max-width: 767.98px) {
                .mobile-hide{
                    display:none;
                }
                .flex-mobile{
                    display: flex;
                }
                .wrap:{
                    flex-wrap: wrap;
                }
                .hr {
                  display: block;
                  height: 3px;
                  border: 0;
                  margin: 0;
                  padding: 0;
                  width:100%;
                }  
              }
              @media only screen and (min-width: 768px) {
                .header-container {
                    display:flex;
                }
              }
          `}</style>
        );
    } else return null;
};


const SpeakerDetail = () => {
    const { id } = useParams()
    const [dataDetail, setDataDetail] = useState()
    const [error, setError] = useState({})

    const [key, setKey] = useState('biografi');

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [page_count, set_page_count] = useState(0);
    const [page, set_page] = useState(1);
    const [perPage, setPerPage] = useState(6);

    const { t } = useTranslation()

    useEffect(() => {
        getDetailSpeaker()
    }, [])

    useEffect(() => {
        getEventList()
    }, [page, dataDetail])

    const getDetailSpeaker = () => {
        let url = `${process.env.REACT_APP_BASE_API_URL}webinar/getSpeaker/detail/${id}`
        axios.get(url, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        })).then(response => {
            setDataDetail(response.data.data);
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    const getEventList = () => {
        if (!dataDetail)
            return;

        let url = `${process.env.REACT_APP_BASE_API_URL}webinar/getEvent`;
        let params = {
            page: page,
            per_page: perPage,
            webinar_speaker_id: dataDetail?.id
        }
        axios.get(url, Config({
            Authorization: 'Bearer ' + Cookies.get('token')
        }, params
        )).then(res => {
            setEvents(res.data.data.data);
            set_page_count(res.data.data.last_page)
        }).catch(error => {
            console.error('error banner: ', error);
        }).finally(() => setLoading(false))
    }

    const onPageChange = (page) => {
        set_page(page)
    }

    const setExpLabel = (level) => {
        let result = optionExpertiseLevel.find(x => x.value === level)
        if (result) {
            return result.label
        } else {
            return level
        }
    }

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <Template>
                <MyContext.Consumer>{context => (
                    <div id="body" className="my-4">
                        <Styles themes={context.theme_settings} />
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="bg-white shadow-graph rounded p-3 " style={{ top: 0 }}>
                                    <div className="header-container align-items-center justify-content-between mb-3">
                                        <div className='w-100 d-flex'>
                                            <div className=''>
                                                <CustomImage
                                                    folder={PublicStorageFolderPath.cms}
                                                    filename={dataDetail?.image}
                                                    style={{ width: 80, height: 80, borderRadius: 50 }}
                                                    className="object-fit-cover"
                                                />
                                            </div>
                                            <div className='w-100 header-container'>
                                                <div className='col-sm-7 align-self-center mb-0'>
                                                    <div className='d-flex align-items-center'>
                                                        <h6 className='h6 font-weight-bold color-black mt-1'>{dataDetail?.name}</h6>
                                                        <div className='d-flex ml-3' style={{ backgroundColor: '#F8931D', textAlign: 'center', borderRadius: 50, height: 30, alignItems: 'center' }}>
                                                            <i className="fas fa-crown ml-2 mr-1" style={{ color: 'white' }}></i>
                                                            <p className='small mr-3' style={{ color: 'white' }}>{setExpLabel(dataDetail?.expertise_level)}</p>
                                                        </div>
                                                    </div>
                                                    <p className='small'>{t('webinar.specialityIn')} {dataDetail?.expertise}</p>
                                                </div>
                                                <div className='col-sm-3 d-flex mobile-hide'>
                                                    <h6 className='h6 font-weight-bold color-black mobile-hide'>{t('webinar.classes')}</h6>
                                                </div>
                                                <div className='col-sm-2 column ml-3 mt-0'>
                                                    <h6 className='h6 font-weight-bold color-black mobile-hide'>{t('webinar.rating')}</h6>
                                                    <div className="mt-2 row">
                                                        {
                                                            (dataDetail?.rating) &&
                                                            <div className='flex-mobile'>
                                                                <p className="font-size-80-percent mr-2 color-FFB200" style={{ width: 'auto' }}>{dataDetail?.rating}</p>
                                                                <div className="d-flex">
                                                                    {[1, 2, 3, 4, 5].map(rating => (<div key={rating}>
                                                                        {rating <= dataDetail?.rating ? <i className="fas fa-star color-FFB200" /> : <i className="fas fa-star color-858585" />}
                                                                    </div>))}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className=''>
                                        {(dataDetail !== null) &&
                                            <Responsive mobile={
                                                <>
                                                    <div className='d-flex w-100 justify-content-between'>
                                                        {
                                                            ['biografi', 'ulasan', 'diskusi', 'kelas'].map((item, index) => {
                                                                return (
                                                                    <div className='my-1 w-100 text-center' onClick={() => setKey(item)}>
                                                                        <div style={{ color: key === item ? '#F8931D' : '#DCDCDC', fontWeight: '600' }}>{capitalize(item)}</div>
                                                                        <div className='hr' style={{ marginTop: 5, backgroundColor: key === item ? '#F8931D' : '#DCDCDC' }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className={`${key !== 'biografi' ? 'mobile-hide' : null}`}>
                                                        <div className='shadow-graph pb-2'>
                                                            <TinyMceContent className='body color-black m-4'>
                                                                {dataDetail?.bio}
                                                            </TinyMceContent>
                                                        </div>
                                                    </div>
                                                    <div className={`${key !== 'ulasan' ? 'mobile-hide' : null}`}>
                                                        {
                                                            (dataDetail) &&
                                                            <>
                                                                <SpeakerListReview
                                                                    type={'type_2'}
                                                                    speakerDetail={dataDetail}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className={`${key !== 'diskusi' ? 'mobile-hide' : null}`}>
                                                        {
                                                            (dataDetail) &&
                                                            <>
                                                                <SpeakerDiscussion
                                                                    speakerDetail={dataDetail}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className={`${key !== 'kelas' ? 'mobile-hide' : null}`}>
                                                        <div className='row'>
                                                            {events.map((event) => {
                                                                return (
                                                                    <EventCard key={event.id} event={event} />
                                                                )
                                                            })}
                                                        </div>
                                                        <Paginate pageCount={page_count} onPageChange={onPageChange} initialPage={page} />
                                                    </div>
                                                </>
                                            } desktop={
                                                <Tabs id="controlled-tab" className="mb-3" activeKey={key} onSelect={(k) => setKey(k)}>
                                                    <Tab tabClassName="mx-0 mx-sm-0 mx-md-3 mx-2" eventKey="biografi" title="Biografi">
                                                        <div className='shadow-graph'>
                                                            <TinyMceContent className='body color-black m-4'>
                                                                {dataDetail?.bio}
                                                            </TinyMceContent>
                                                        </div>
                                                    </Tab>
                                                    {/* <Tab tabClassName="mx-0 mx-sm-0 mx-md-3 mx-4 " eventKey="terbaru" title={t('forum_latest')}>
                                            </Tab> */}
                                                    <Tab tabClassName="mx-0 mx-sm-0 mx-md-3 mx-4 " eventKey="ulasan" title="Ulasan">
                                                        {
                                                            (dataDetail) &&
                                                            <>
                                                                <SpeakerListReview
                                                                    type={'type_2'}
                                                                    speakerDetail={dataDetail}
                                                                />
                                                            </>
                                                        }

                                                    </Tab>
                                                    <Tab tabClassName="mx-0 mx-sm-0 mx-md-3 mx-4 " eventKey="diskusi" title="Diskusi">
                                                        {
                                                            (dataDetail) &&
                                                            <>
                                                                <SpeakerDiscussion
                                                                    speakerDetail={dataDetail}
                                                                />
                                                            </>
                                                        }
                                                    </Tab>
                                                    <Tab tabClassName="mx-0 mx-sm-0 mx-md-3 mx-4 " eventKey="event" title="Kelas">
                                                        <div className='row'>
                                                            {events.map((event) => {
                                                                return (
                                                                    <EventCard key={event.id} event={event} />
                                                                )
                                                            })}
                                                        </div>
                                                        <Paginate pageCount={page_count} onPageChange={onPageChange} initialPage={page} />
                                                    </Tab>
                                                </Tabs>
                                            }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}</MyContext.Consumer>
            </Template>
        </>
    );
}


export default SpeakerDetail