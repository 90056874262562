import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import GeneralRoutePath from '../../GeneralRoutePath'
import axios from 'axios';
import Cookies from 'js-cookie';
import Config from '../../../../components/axios/Config';
import SwalToast from '../../../../components/helpers/SwalToast';
import MyContext from '../../../../components/MyContext'

const Tab = ({ to, label }) => {
    const myContext = useContext(MyContext)

    return (
        <NavLink
            to={to}
            className={`button-nowrap px-3 py-2`}
            style={{
                borderBottom: '1px solid #dee2e6'
            }}
            activeStyle={{
                borderBottom: `2px solid ${myContext.theme_settings?.accent_color.value}`,
                fontWeight: 700,
            }}
        >
            <p>{label}</p>
        </NavLink>
    )
}

export default function SelectCategory() {
    const [categories, SetCategories] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = () => {
        let url = `${process.env.REACT_APP_BASE_API_URL}forum/categories/get`;
        let category = []
        axios.get(url, Config({
            Authorization: `Bearer ${Cookies.get("token")}`,
        })).then(response => {
            category = response.data.data.map((item) => (
                item.parent_id === null &&
                {
                    name: item.name,
                    id: item.id,
                    slug: item.slug,
                }
            ))
            SetCategories(category.filter(x => !!x))
        }).catch(error => {
            console.log(error)
            if (error.response) {
                SwalToast.fire({
                    icon: "error",
                    title: error.response.data.message
                })
            } else {
                SwalToast.fire({
                    icon: "error",
                    title: "Something went wrong",
                })
            }
        })
    }

    return (
        <>
            <div className='d-flex overflow-auto border-bottom'>
                <Tab
                    to={GeneralRoutePath.FORUM_MY_CATEGORY}
                    label={"Semua"}
                />

                {categories && categories.map((i) => (
                    <Tab key={i.id}
                        to={GeneralRoutePath.FORUM_MY_SELECT_CATEGORY.replace(':slug', i.slug)}
                        label={i.name}
                    />
                ))}
            </div >
        </>
    )
}

