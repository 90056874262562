import { useState } from "react"

const Share = () => {
    const [copied, set_copied] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(window.location.href)
        console.log(window.location.href)
        set_copied(true)
        setTimeout(() => {
            set_copied(false)
        }, 5000);
    }
    return (<div className="" style={{ display: 'grid', gridTemplateColumns: 'repeat(1,1fr)', gap: 5 }}>
        <div className="text-center">
            <div className="btn pointer" onClick={copy}>
                <div><i style={{ fontSize: '4em' }} className="far fa-copy"></i></div>
                {copied ? <div className="mt-2 color-0C9344"> Copied!</div>
                    : <div className="mt-2"> Copy URL</div>}
            </div>
        </div>

    </div>)
}

export default Share