
const MobileTab = ({ onClick, slug, activeKey, label }) => (
    <div
        onClick={() => onClick(slug)}
        className={`${slug === activeKey && "bgc-accent-color"} mx-2 button-nowrap cursor-pointer`}
        style={{
            padding: 10,
            borderWidth: 1,
            borderColor: "#FFF",
            borderRadius: 50,
            backgroundColor: "#F5F5F5",
        }}
    >
        <p>{label}</p>
    </div>
)

export default MobileTab