
import Cookies from 'js-cookie';
import moment from 'moment';
import IsEmpty from './helpers/IsEmpty';

const ListLangLocale = {
    id: "id",
    en: "en-gb",
    jv: "jv",
    zh: "zh-cn",
    ja: "ja"
}

export const SetMomentLocale = () => {
    let name = Cookies.get('language_code')
    if (!IsEmpty(name)) {
        moment.locale(`${ListLangLocale[name]}`)
    } else {
        moment.locale(ListLangLocale['en'])
    }
}